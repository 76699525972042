import { j as t } from "./chunks/jsx-runtime.82a9ddf6.js";
import { AnchorOrButton as o } from "./anchorOrButton.mjs";
import "react";
import "./chunks/clsx.0d472180.js";
import "./chunks/styles.7e0f5eb5.js";
const r = ({ href: e, ...n }) => /* @__PURE__ */ t.jsx(o, { href: e, target: "_blank", rel: "nofollow noopener noreferrer", ...n });
try {
  r.displayName = "ExternalLink", r.__docgenInfo = { description: "", displayName: "ExternalLink", props: { as: { defaultValue: null, description: "", name: "as", required: !1, type: { name: "ElementType<any, keyof IntrinsicElements>" } }, unstyled: { defaultValue: null, description: "", name: "unstyled", required: !1, type: { name: "boolean" } } } };
} catch {
}
export {
  r as ExternalLink
};
