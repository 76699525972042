import { j as a } from "./chunks/jsx-runtime.82a9ddf6.js";
import "./anchorOrButton.mjs";
import "./externalLink.mjs";
import { Button as n } from "./button.mjs";
import "./linkButton.mjs";
import { g as r } from "./chunks/styles.7e0f5eb5.js";
import "react";
import "./chunks/clsx.0d472180.js";
import "./skeletonLoader.mjs";
import "./chunks/useTranslatableUILabel.cc84b4da.js";
import "./chunks/TranslatableUIProvider.f5945ac7.js";
import "./skeletonWrapper.mjs";
import "./spinner.mjs";
import "./heading.mjs";
import "./chunks/cn.2ea4f521.js";
import "./chunks/TypographyElement.3bd13943.js";
import "./text.mjs";
import "./icon.mjs";
import "./chunks/index.29668fd9.js";
import "./chunks/motion.24089995.js";
const t = ({ ...e }) => /* @__PURE__ */ a.jsx(n, { className: r.root, size: "s", ...e });
try {
  t.displayName = "TextFieldButton", t.__docgenInfo = { description: "", displayName: "TextFieldButton", props: { as: { defaultValue: null, description: "", name: "as", required: !1, type: { name: "ElementType<any, keyof IntrinsicElements>" } }, variant: { defaultValue: null, description: "", name: "variant", required: !1, type: { name: "ButtonVariant" } }, unstyled: { defaultValue: null, description: "", name: "unstyled", required: !1, type: { name: "boolean" } }, fullWidth: { defaultValue: null, description: "", name: "fullWidth", required: !1, type: { name: "boolean" } }, leadingIcon: { defaultValue: null, description: "", name: "leadingIcon", required: !1, type: { name: "string | number | boolean | ReactElement<any, string | JSXElementConstructor<any>> | Iterable<ReactNode> | ReactPortal" } }, trailingIcon: { defaultValue: null, description: "", name: "trailingIcon", required: !1, type: { name: "string | number | boolean | ReactElement<any, string | JSXElementConstructor<any>> | Iterable<ReactNode> | ReactPortal" } }, leadingIconClassName: { defaultValue: null, description: "", name: "leadingIconClassName", required: !1, type: { name: "string" } }, trailingIconClassName: { defaultValue: null, description: "", name: "trailingIconClassName", required: !1, type: { name: "string" } }, loading: { defaultValue: null, description: "", name: "loading", required: !1, type: { name: "boolean" } }, progressPercentage: { defaultValue: null, description: "", name: "progressPercentage", required: !1, type: { name: "number" } } } };
} catch {
}
export {
  t as TextFieldButton
};
