import { j as s } from "./chunks/jsx-runtime.82a9ddf6.js";
import { forwardRef as c } from "react";
import { c as u } from "./chunks/clsx.0d472180.js";
import { q as i } from "./chunks/styles.7e0f5eb5.js";
const a = c(
  ({ as: t, className: l, href: e, unstyled: m = !1, disabled: o, ...p }, r) => {
    const n = {
      className: u(!m && i.root, l),
      ...p
    };
    return t ? /* @__PURE__ */ s.jsx(t, { href: e, ref: r, disabled: o, ...n }) : e && !o ? /* @__PURE__ */ s.jsx("a", { href: e, ref: r, ...n }) : /* @__PURE__ */ s.jsx(
      "button",
      {
        type: "button",
        disabled: o,
        ref: r,
        ...n
      }
    );
  }
);
try {
  a.displayName = "AnchorOrButton", a.__docgenInfo = { description: "", displayName: "AnchorOrButton", props: { as: { defaultValue: null, description: "", name: "as", required: !1, type: { name: "ElementType<any, keyof IntrinsicElements>" } }, unstyled: { defaultValue: { value: !1 }, description: "", name: "unstyled", required: !1, type: { name: "boolean" } } } };
} catch {
}
export {
  a as AnchorOrButton
};
