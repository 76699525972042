import { j as e } from "./chunks/jsx-runtime.82a9ddf6.js";
import { forwardRef as q } from "react";
import { c as r } from "./chunks/clsx.0d472180.js";
import "./skeletonLoader.mjs";
import "./skeletonWrapper.mjs";
import { Spinner as A } from "./spinner.mjs";
import "./heading.mjs";
import { Text as R } from "./text.mjs";
import { Icon as S } from "./icon.mjs";
import { AnchorOrButton as T } from "./anchorOrButton.mjs";
import { r as n } from "./chunks/styles.7e0f5eb5.js";
import { A as j } from "./chunks/index.29668fd9.js";
import { m as B } from "./chunks/motion.24089995.js";
import "./chunks/useTranslatableUILabel.cc84b4da.js";
import "./chunks/TranslatableUIProvider.f5945ac7.js";
import "./chunks/cn.2ea4f521.js";
import "./chunks/TypographyElement.3bd13943.js";
const E = {
  xxl: "m",
  l: "m",
  m: "s",
  s: "s"
}, v = {
  xxl: "m",
  l: "m",
  m: "s",
  s: "s"
}, C = {
  hidden: { opacity: 0, scale: 0 },
  replacing: { opacity: 0.5, scale: 0.5 },
  visible: { opacity: 1, scale: 1 }
}, O = {
  duration: 0.1,
  type: "spring",
  bounce: 0
}, f = q(
  ({
    variant: s = "yellow",
    size: a = "m",
    fullWidth: y,
    leadingIcon: m,
    trailingIcon: l,
    children: I,
    leadingIconClassName: N,
    trailingIconClassName: c,
    loading: t,
    className: x,
    disabled: g,
    progressPercentage: i,
    ..._
  }, h) => {
    const b = E[a], V = !!(t || l), d = l ? "replacing" : "hidden", u = (o, p) => typeof o == "string" ? /* @__PURE__ */ e.jsx(S, { className: p, name: o, size: b }) : /* @__PURE__ */ e.jsx(
      "span",
      {
        className: r(p, "inline-flex", {
          "*:size-2": a === "s" || a === "m",
          "*:size-3": a === "l" || a === "xxl"
        }),
        children: o
      }
    );
    return /* @__PURE__ */ e.jsxs(
      T,
      {
        ref: h,
        className: r(
          n.root,
          n[s],
          n[a],
          y && n.fullWidth,
          t && n.loading,
          x
        ),
        disabled: g || t,
        ..._,
        children: [
          m && u(m, r(n.leadingIcon, N)),
          I,
          /* @__PURE__ */ e.jsx(j, { mode: "wait", initial: !1, children: V && /* @__PURE__ */ e.jsx(
            B.span,
            {
              variants: C,
              initial: d,
              animate: "visible",
              exit: d,
              transition: O,
              className: n.iconWrapper,
              children: t ? /* @__PURE__ */ e.jsxs(e.Fragment, { children: [
                /* @__PURE__ */ e.jsx(
                  A,
                  {
                    variant: "currentColor",
                    size: v[a],
                    className: r(
                      n.trailingIcon,
                      c,
                      i && "!ml-0"
                    )
                  }
                ),
                i && /* @__PURE__ */ e.jsx(R, { variant: "xs+", textColor: "light", className: "pl-0.5", children: `${i}%` })
              ] }) : l && u(l, r(n.trailingIcon, c))
            },
            t ? "loading" : "icon"
          ) })
        ]
      }
    );
  }
);
try {
  f.displayName = "Button", f.__docgenInfo = { description: "", displayName: "Button", props: { as: { defaultValue: null, description: "", name: "as", required: !1, type: { name: "ElementType<any, keyof IntrinsicElements>" } }, unstyled: { defaultValue: null, description: "", name: "unstyled", required: !1, type: { name: "boolean" } }, variant: { defaultValue: { value: "yellow" }, description: "", name: "variant", required: !1, type: { name: "ButtonVariant" } }, size: { defaultValue: { value: "m" }, description: "", name: "size", required: !1, type: { name: "ButtonSize" } }, fullWidth: { defaultValue: null, description: "", name: "fullWidth", required: !1, type: { name: "boolean" } }, leadingIcon: { defaultValue: null, description: "", name: "leadingIcon", required: !1, type: { name: "string | number | boolean | ReactElement<any, string | JSXElementConstructor<any>> | Iterable<ReactNode> | ReactPortal" } }, trailingIcon: { defaultValue: null, description: "", name: "trailingIcon", required: !1, type: { name: "string | number | boolean | ReactElement<any, string | JSXElementConstructor<any>> | Iterable<ReactNode> | ReactPortal" } }, leadingIconClassName: { defaultValue: null, description: "", name: "leadingIconClassName", required: !1, type: { name: "string" } }, trailingIconClassName: { defaultValue: null, description: "", name: "trailingIconClassName", required: !1, type: { name: "string" } }, loading: { defaultValue: null, description: "", name: "loading", required: !1, type: { name: "boolean" } }, progressPercentage: { defaultValue: null, description: "", name: "progressPercentage", required: !1, type: { name: "number" } } } };
} catch {
}
export {
  f as Button
};
