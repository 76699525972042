import { j as i } from "./chunks/jsx-runtime.82a9ddf6.js";
import { forwardRef as f } from "react";
import { c as r } from "./chunks/clsx.0d472180.js";
import { Icon as y } from "./icon.mjs";
import { s as e } from "./chunks/styles.7e0f5eb5.js";
const n = f(
  ({
    as: s = "div",
    className: l,
    variant: t = "outlined",
    size: o = "m",
    emphasized: m = !1,
    icon: a,
    children: d,
    iconClassName: p,
    ...u
  }, c) => /* @__PURE__ */ i.jsxs(
    s,
    {
      ref: c,
      className: r(
        e.root,
        e[t],
        e[o],
        m && e.emphasized,
        !!a && e.hasIcon,
        l
      ),
      ...u,
      children: [
        !!a && /* @__PURE__ */ i.jsx(y, { name: a, size: "s", className: r(e.icon, p) }),
        d
      ]
    }
  )
);
try {
  n.displayName = "Badge", n.__docgenInfo = { description: "", displayName: "Badge", props: { as: { defaultValue: null, description: "", name: "as", required: !1, type: { name: "ElementType<any, keyof IntrinsicElements>" } }, iconClassName: { defaultValue: null, description: "", name: "iconClassName", required: !1, type: { name: "string" } }, variant: { defaultValue: { value: "outlined" }, description: "", name: "variant", required: !1, type: { name: "BadgeVariant" } }, size: { defaultValue: { value: "m" }, description: "", name: "size", required: !1, type: { name: "BadgeSize" } }, emphasized: { defaultValue: { value: !1 }, description: "", name: "emphasized", required: !1, type: { name: "boolean" } }, icon: { defaultValue: null, description: "", name: "icon", required: !1, type: { name: "IconMapMember" } } } };
} catch {
}
export {
  n as Badge
};
